import React, { Component } from 'react';
import axios from 'axios';
import configData from '../../../includes/config.json';
import Loading from '../../../components/Loading';

class AdminLogin extends Component {
	constructor() {
		super();
		this.state = {
			alert_message: '',
			loading: false,
		};
		this.onAdminLoginSubmit = this.onAdminLoginSubmit.bind(this);
	}

	componentDidMount() {
		const accountID = localStorage.getItem('loggedId');
		if (accountID) {
			window.location = '/admin/home';
			return;
		}
	}

	async onAdminLoginSubmit(e) {
		e.preventDefault();
		const data = e.target;
		e.target.submitBtn.disabled = true;
		this.setState({
			alert_message: '',
			loading: true,
		});
		const request = {
			username: data.username.value,
			password: data.password.value,
		};
		var Buffer = require('buffer/').Buffer;
		const username = configData.Authorization.username;
		const password = configData.Authorization.password;
		const token = Buffer.from(`${username}:${password}`, 'utf8').toString('base64');
		const response = await axios({
			method: 'POST',
			url: configData.server_URI + '/wataniya/admin/login',
			data: request,
			headers: {
				Authorization: `Basic ${token}`,
			},
		});
		this.setState({
			loading: false,
		});
		e.target.submitBtn.disabled = false;
		if (response.data === 'error') {
			this.setState({
				alert_message: 'البيانات غير صحيحة',
			});
			return;
		} else {
			localStorage.setItem('loggedId', response.data.UID);
			window.location = '/admin/home';
		}
		e.target.reset();
	}

	render() {
		return (
			<div className="page-content">
				{this.state.loading && <Loading />}
				<form className="register-form" onSubmit={this.onAdminLoginSubmit}>
					<p className="form-title">لوحة الإدارة</p>
					<div className="row">
						<p className="row-title" mandatory="true">
							اسم المستخدم
						</p>
						<input className="row-input" type="text" name="username" placeholder="اسم المستخدم" required />
					</div>
					<div className="row">
						<p className="row-title" mandatory="true">
							كلمة المرور
						</p>
						<input className="row-input" type="password" name="password" placeholder="كلمة المرور" required />
					</div>
					<p className="alert-message">{this.state.alert_message}</p>
					<div className="form-buttons">
						<button className="form-submit-btn" name="submitBtn">
							تسجيل الدخول
						</button>
					</div>
				</form>
			</div>
		);
	}
}

export default AdminLogin;
